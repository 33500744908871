import React from 'react'
import { Container } from 'react-awesome-styled-grid'

import Slogan from '../Slogan'
import BgHero from './background'
import * as S from './styled'

const Hero = () => {
  const _onReady = (event) => {
    event.target.mute()
  }

  const _onEnd = (event) => {
    event.target.playVideo()
  }

  return (
    <S.HeroWrapper>
      <S.HeroHeading>
        <Container>
          <Slogan />
        </Container>
      </S.HeroHeading>
      <S.HeroBackground>
        <S.HeroForeground>
          <S.HeroYouTube
            videoId="dzvZG2o3seU"
            opts={{
              playerVars: {
                modestbranding: 0,
                autoplay: 1,
                controls: 0,
                rel: 0,
                autohide: 0,
                showinfo: 0,
                mute: 1
              },
              host: 'https://www.youtube-nocookie.com'
            }}
            className="video-iframe"
            onReady={_onReady}
            onEnd={_onEnd}
          />
        </S.HeroForeground>
        <BgHero />
      </S.HeroBackground>
    </S.HeroWrapper>
  )
}

export default Hero
