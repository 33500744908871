import button from './button'
import colors from './colors'
import grid from './grid'
import heading from './heading'
import input from './input'
import margin from './margin'
import modal from './modal'
import polished from './polished'
import sizes from './sizes'
import typography from './typography'

export default {
  borderRadius: `${sizes.size4}px`,
  sizes: sizes,
  awesomegrid: grid,
  color: colors,
  margin: margin,
  typography: typography,
  input: input,
  modal: modal,
  button: button,
  heading: heading,
  polished: polished
}
