import YouTube from 'react-youtube'

import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

import media from '../../helpers/media'

export const HeroModalContent = styled.div``

export const BackgroundWrapper = styled(BackgroundImage)`
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 550px;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;

  ${media.lessThan('md')`
    height: 450px;
    display: block;
  `}

  ${media.lessThan('sm')`
    height: 350px;
  `}
`

export const HeroBackground = styled.div`
  background-color: ${({ theme }) => theme.color.wine950};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 0;
  width: 100vw;
  height: 650px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(47, 8, 41, 0.80);
    display: block;
  }

  ${media.lessThan('xl')`
    height: 700px;
  `}

  ${media.lessThan('lg')`
    height: 650px;
  `}

  ${media.lessThan('md')`
    &:after {
      display: none;
    }
  `}

  ${media.lessThan('sm')`
    height: 450px;
  `}
`

export const HeroForeground = styled.div`
  display: block;

  &,
  .video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .video-iframe {
    top: -125px;
  }

  @media (min-aspect-ratio: 16/9) {
    height: 300%;
    top: -100%;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 300%;
    height: 120%;
    left: -100%;
  }

  ${media.lessThan('md')`
    display: none;
  `}
`

export const HeroWrapper = styled.section`
  position: relative;
  width: 100%;
  height: auto;
  min-height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: flex-end;
  margin-bottom: 60px;

  ${media.lessThan('sm')`
    margin-bottom: 0;
  `}
`

export const HeroHeading = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  padding-top: 200px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizes.size10}px;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: '';
    width: 100%;
    height: 250px;
    bottom: -71px;
    position: absolute;
    z-index: -1;
    background-color: ${({ theme }) => theme.color.white};
    clip-path: polygon(0 75%, 100% 0%, 100% 100%, 0% 100%);

    ${media.lessThan('sm')`
      clip-path: polygon(0 55%, 100% 0%, 100% 100%, 0% 100%);
      height: 200px;
    `}
  }

  ${media.lessThan('sm')`
    padding-top: 120px;
    padding-bottom: 100px;

    &:before {
      height: 150px;
      clip-path: polygon(0px 55%, 100% 0%, 100% 100%, 0% 100%);
      bottom: -35px;
    }
  `}
`

export const HeroSkew = styled.div`
  width: 100%;
  min-height: 360px;
  background-color: ${({ theme }) => theme.color.red950};
  position: relative;
  z-index: 4;
`

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;

  ${media.lessThan('sm')`
    padding-bottom: 100px;
  `}
`

export const HeroYouTube = styled(YouTube)`
  display: block;
  min-height: 500px;
`
