export default {
  padding: '12px 16px',
  height: '42px',
  label: {
    focus: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '12px'
    }
  },
  autocomplete: {
    background: '#e4e9f2',
    color: '#495160'
  }
}
