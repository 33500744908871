import styled from 'styled-components'

import media from '../../helpers/media'
import Icon from '../Icon'

export const SocialWrapper = styled.nav``

export const SocialMenu = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const SocialItem = styled.li`
  margin-left: ${({ theme }) => theme.sizes.size10}px;
  margin-right: ${({ theme }) => theme.sizes.size10}px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export const SocialLink = styled.a`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.white};
  border-radius: 100%;
  width: ${({ theme }) => theme.sizes.size32}px;
  height: ${({ theme }) => theme.sizes.size32}px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.color.wine950};
    border-color: ${({ theme }) => theme.color.wine950};
  }
`

export const SocialText = styled.span`
  display: none;
`

export const SocialIcon = styled(Icon)`
  width: ${({ theme }) => theme.sizes.size16}px;
  height: ${({ theme }) => theme.sizes.size16}px;
  fill: ${({ theme }) => theme.color.white};
`
