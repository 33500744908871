import React from 'react'

import { XperienceArrow } from '../Isometric'
import * as S from './styled'

const Slogan = () => (
  <S.SloganWrapper>
    <S.SloganTitle>
      <S.SloganCompany>Superlógica</S.SloganCompany>
      <S.SloganEvent>Xperience</S.SloganEvent>
      <S.SloganDetails>
        <S.SloganInfo>
          <S.SloganCity>Campinas - SP</S.SloganCity>
        </S.SloganInfo>
      </S.SloganDetails>
    </S.SloganTitle>
    <S.SloganArrowGroup>
      <S.SloganArrow>
        <XperienceArrow />
      </S.SloganArrow>
      <S.SloganArrow>
        <XperienceArrow />
      </S.SloganArrow>
    </S.SloganArrowGroup>
  </S.SloganWrapper>
)

export default Slogan
