import React from 'react'

import { graphql, StaticQuery } from 'gatsby'

import { BackgroundWrapper } from './styled'

const BgHero = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero/bg-hero.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundWrapper fluid={imageData} preserveStackingContext={true} />
      )
    }}
  />
)

export default BgHero
