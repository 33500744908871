import React from 'react'
import { Container } from 'react-awesome-styled-grid'

import Social from '../Social'
import links from './links'
import * as S from './styled'

const Footer = () => (
  <S.FooterWrapper>
    <S.FooterSupport>
      <Container>
        <S.FooterContent>
          <S.FooterTitle>
            <span>Alguma dúvida?</span> Fale com a gente
          </S.FooterTitle>
          <S.FooterSupportLink
            href="mailto:imprensa@superlogica.com"
            title="imprensa@superlogica.com"
          >
            imprensa@superlogica.com
          </S.FooterSupportLink>
          <S.FooterSupportLink href="tel:08007096800" title="0800 709 6800">
            0800 709 6800
          </S.FooterSupportLink>
          <S.FooterSocial>
            <Social />
          </S.FooterSocial>
        </S.FooterContent>
      </Container>
    </S.FooterSupport>
    <S.FooterCompliance>
      <Container>
        <S.FooterContent>
          <S.FooterCopyright>
            <S.FooterText>
              Superlógica Tecnologias S.A. - CNPJ nº 04.833.541/0001-51{' '}
              <span>
                Rua Joaquim Vilac nº 501, Vila Teixeira - CEP. 13.032-385 -
                Campinas/SP
              </span>
            </S.FooterText>
          </S.FooterCopyright>
          <S.FooterMenu>
            <S.FooterMenuList>
              {links.map((link, i) => (
                <S.FooterMenuItem key={i}>
                  <S.FooterMenuLink
                    href={link.url}
                    title={link.label}
                    rel={link.rel}
                    target={link.target}
                  >
                    {link.label}
                  </S.FooterMenuLink>
                </S.FooterMenuItem>
              ))}
            </S.FooterMenuList>
          </S.FooterMenu>
        </S.FooterContent>
      </Container>
    </S.FooterCompliance>
  </S.FooterWrapper>
)

export default Footer
