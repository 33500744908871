import { between } from 'polished'
import styled from 'styled-components'

import media from '../../helpers/media'

export const SloganWrapper = styled.div`
  position: relative;
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${media.lessThan('md')`
    margin-left: -${({ theme }) => theme.sizes.size32}px;
  `}

  ${media.lessThan('sm')`
    margin-left: auto;
  `}
`

export const SloganTitle = styled.div`
  position: relative;
  z-index: 3;
`

export const SloganCompany = styled.h2`
  font-size: ${({ theme }) => theme.sizes.size24}px;
  font-weight: 600;
  text-transform: uppercase;
`

export const SloganEvent = styled.p`
  font-family: var(--integralcf);
  font-size: ${between('48px', '120px', '320px', '1920px')};
  line-height: 0.7;
  font-weight: 600;
`

export const SloganDetails = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: ${between('16px', '24px', '320px', '1920px')};
  display: flex;
  align-items: center;
`

export const SloganYear = styled.span`
  font-family: var(--integralcf);
  font-size: ${between('40px', '80px', '320px', '1920px')};
  line-height: 1.5;
  margin-right: ${({ theme }) => theme.sizes.size40}px;

  ${media.lessThan('md')`
    margin-right: ${({ theme }) => theme.sizes.size16}px;
  `}
`

export const SloganInfo = styled.div`
  margin-top: ${({ theme }) => theme.sizes.size32}px;
`

export const SloganDate = styled.span`
  font-weight: 700;
`

export const SloganCity = styled.span`
  display: block;
  line-height: 1.4;
  ${between('24px', '32px', '320px', '1920px')};
`

export const SloganArrow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;

  &:first-of-type {
    z-index: 1;
    right: 50px;
  }

  &:last-of-type {
    z-index: -2 !important;
    top: 32px;
    right: 0;
    opacity: 0.6;
  }

  ${media.lessThan('lg')`
    &:last-of-type {
      top: 24px;
    }
  `}

  ${media.lessThan('sm')`
    &:first-of-type {
      right: 24px;
    }

    &:last-of-type {
      top: 12px;
    }
  `}
`

export const SloganArrowGroup = styled.div`
  width: 280px;
  height: 400px;
  top: -90px;
  right: 130px;
  display: block;
  position: absolute;

  ${media.greaterThan('xl')`
    right: 220px;
  `}

  ${media.lessThan('lg')`
    width: 240px;
    height: 360px;
    right: 100px;
    top: -80px;
  `}

  ${media.lessThan('md')`
    top: -100px;
  `}

  ${media.lessThan('sm')`
    width: 150px;
    height: 243px;
    top: -40px;
    right: 70px;
  `}

  ${media.lessThan('xs')`
    right: 0;
  `}
`
