export default {
  h1: {
    label: {
      fontSize: 16,
      lineHeight: 20
    },
    max: {
      fontSize: 54,
      lineHeight: 62
    },
    min: {
      fontSize: 32,
      lineHeight: 36
    }
  },
  h2: {
    label: {
      fontSize: 16,
      lineHeight: 20
    },
    max: {
      fontSize: 36,
      lineHeight: 48
    },
    min: {
      fontSize: 24,
      lineHeight: 36
    }
  },
  h3: {
    label: {
      fontSize: 16,
      lineHeight: 20
    },
    max: {
      fontSize: 18,
      lineHeight: 24
    },
    min: {
      fontSize: 16,
      lineHeight: 24
    }
  },
  h4: {
    label: {
      fontSize: 16,
      lineHeight: 20
    },
    max: {
      fontSize: 18,
      lineHeight: 28
    },
    min: {
      fontSize: 16,
      lineHeight: 24
    }
  },
  h5: {
    label: {
      fontSize: 16,
      lineHeight: 20
    },
    max: {
      fontSize: 18,
      lineHeight: 28
    },
    min: {
      fontSize: 16,
      lineHeight: 24
    }
  },
  h6: {
    label: {
      fontSize: 16,
      lineHeight: 20
    },
    max: {
      fontSize: 16,
      lineHeight: 24
    },
    min: {
      fontSize: 16,
      lineHeight: 24
    }
  }
}
