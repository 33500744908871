export default [
  {
    label: 'Facebook',
    url: 'https://www.facebook.com/superlogica',
    target: '_blank',
    rel: 'noopener noreferrer',
    icon: 'Facebook'
  },
  // {
  //   label: 'Twitter',
  //   url: 'https://twitter.com/superlogica',
  //   target: '_blank',
  //   rel: 'noopener noreferrer',
  //   icon: 'Twitter'
  // },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/superlogicatec',
    target: '_blank',
    rel: 'noopener noreferrer',
    icon: 'Instagram'
  },
  {
    label: 'Linkedin',
    url: 'https://www.linkedin.com/company/superlogica-tecnologias',
    target: '_blank',
    rel: 'noopener noreferrer',
    icon: 'Linkedin'
  },
  {
    label: 'Youtube',
    url: 'https://www.youtube.com/channel/UCOZYO-qvf4DDgXXL8i23v_g',
    target: '_blank',
    rel: 'noopener noreferrer',
    icon: 'Youtube'
  }
]
