import React, { useState, useEffect, useRef } from 'react'
import { Container } from 'react-awesome-styled-grid'
import { scroller } from 'react-scroll'
import ScrollLock from 'react-scrolllock'

import PropTypes from 'prop-types'

import useWindowSize from '../../hooks/useWindowSize'
import links from './links'
import * as S from './styled'

const Header = ({ className }) => {
  const { height } = useWindowSize()
  const [open, setOpen] = useState(false)
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= -33)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const sections = links.map((link) => link.to)

  return (
    <>
      <ScrollLock isActive={open} />
      <S.HeaderWrapper ref={ref}>
        <S.HeaderNavbar
          className={`sticky-${isSticky ? 'enabled' : 'disabled'} menu-${
            open ? 'opened' : 'closed'
          }`}
          open={open}
        >
          <Container>
            <S.HeaderContent>
              <S.HeaderBrand dark={isSticky} open={open} />
              <S.HeaderNav open={open} vh={`${height * 0.01}px`}>
                <S.HeaderMenuList
                  items={['intro', ...sections]}
                  currentClassName="is-active"
                >
                  {links.map((link, i) => (
                    <S.HeaderMenuItem key={i}>
                      <S.HeaderMenuLink
                        smooth={true}
                        spy={true}
                        to={link.to}
                        hashSpy={true}
                        onClick={() => setOpen(false)}
                        offset={link.offset || 0}
                        title={link.label}
                      >
                        {link.label}
                      </S.HeaderMenuLink>
                    </S.HeaderMenuItem>
                  ))}
                </S.HeaderMenuList>
                <S.HeaderButton
                  onClick={() =>
                    scroller.scrollTo('receba-novidades', {
                      smooth: true,
                      spy: true,
                      hashSpy: true,
                      offset: -120
                    })
                  }
                  color={isSticky && !open ? 'gray950' : 'white'}
                  title="Receber novidades"
                  className={className}
                >
                  Receber novidades
                </S.HeaderButton>
              </S.HeaderNav>
            </S.HeaderContent>
          </Container>
        </S.HeaderNavbar>
      </S.HeaderWrapper>
    </>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header
