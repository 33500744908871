import React from 'react'

import links from './links'
import * as S from './styled'

const Social = () => (
  <S.SocialWrapper>
    <S.SocialMenu>
      {links.map((link, i) => (
        <S.SocialItem key={i}>
          <S.SocialLink
            href={link.url}
            title={link.label}
            rel={link.rel}
            target={link.target}
          >
            {link.icon && <S.SocialIcon name={link.icon} />}
            <S.SocialText>{link.label}</S.SocialText>
          </S.SocialLink>
        </S.SocialItem>
      ))}
    </S.SocialMenu>
  </S.SocialWrapper>
)

export default Social
