const gutterSize = 32
const paddingColumn = gutterSize / 2

export default {
  none: 0 + 'px',
  xxxs: paddingColumn / 4 + 'px',
  xxs: paddingColumn / 2 + 'px',
  xs: paddingColumn + 'px',
  sm: paddingColumn + paddingColumn / 2 + 'px',
  md: gutterSize + 'px',
  lg: gutterSize + paddingColumn / 2 + 'px',
  xl: gutterSize + paddingColumn,
  xxl: gutterSize * 2 + 'px',
  xxxl: gutterSize * 2 + paddingColumn + 'px'
}
