import Facebook from '../../../static/images/icons/facebook.svg'
import Instagram from '../../../static/images/icons/instagram.svg'
import Linkedin from '../../../static/images/icons/linkedin.svg'
import Twitter from '../../../static/images/icons/twitter.svg'
import Youtube from '../../../static/images/icons/youtube.svg'

const icons = {
  Facebook: Facebook,
  Instagram: Instagram,
  Twitter: Twitter,
  Youtube: Youtube,
  Linkedin: Linkedin
}

export default icons
