import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const XperienceText = ({ className, ...props }) => (
  <S.XperienceText className={className} {...props} />
)

XperienceText.propTypes = {
  className: PropTypes.string
}

export default XperienceText
