export default {
  gray100: '#E9EBEC',
  gray200: '#D5D7DA',
  gray300: '#C0C3C7',
  gray400: '#ABB0B4',
  gray500: '#969CA1',
  gray600: '#82888F',
  gray700: '#6D747C',
  gray800: '#58616A',
  gray900: '#424D57',
  gray950: '#2F3A45',
  white: '#ffffff',
  neutral: '#C5CEE1',
  red850: '#D12525',
  red950: '#ff2d2d',
  wine750: '#54344F',
  wine850: '#2F0829',
  wine950: '#2F0829',
  light: '#EDEDED',
  topic1: '#1A57F0',
  topic2: '#00E3B8',
  topic3: '#FF393E',
  topic4: '#FFA100'
}
