import React from 'react'

import PropTypes from 'prop-types'

import icons from './icons'

const IconComponent = ({ name, className, ...props }) => {
  const Icon = icons[name]
  return <Icon className={className} {...props} />
}

IconComponent.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default IconComponent
