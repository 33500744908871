import styled, { keyframes } from 'styled-components'

import arrow from '../../../static/images/isometrics/x-arrow.svg'
import media from '../../helpers/media'

const animatedBackground = keyframes`
  from {
    background-position: 0 102%
  }

  to {
    background-position: -1000% 102%
  }
`

export const XperienceArrow = styled(arrow)`
  width: 100%;
  height: 100%;
  display: block;
`

export const XperienceText = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;
  height: 200px;
  background: url(./images/isometrics/xperience.svg);
  background-repeat: repeat-x;
  background-position: center left;
  margin-top: -${({ top }) => top || 220}px;
  z-index: -1;
  opacity: 0.6;
  animation: ${animatedBackground} 200s linear infinite;

  ${media.lessThan('md')`
    top: 400px;
  `}
`
