import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const XperienceArrow = ({ className, ...props }) => (
  <S.XperienceArrow className={className} {...props} />
)

XperienceArrow.propTypes = {
  className: PropTypes.string
}

export default XperienceArrow
