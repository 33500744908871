import { Link } from 'react-scroll'

import { ButtonOutline } from '@superlogica/button'
import { rgba, rem } from 'polished'
import styled from 'styled-components'

import media from '../../helpers/media'
import Brand from '../Brand'

export const HeaderWrapper = styled.header``

export const HeaderBrand = styled(Brand)``

export const HeaderMenu = styled.nav`
  text-transform: lowercase;

  ${media.lessThan('md')`
    align-items: flex-start;
    flex-direction: column;
  `}
`

export const HeaderContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.sizes.size80}px;
`

export const HeaderNav = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('md')`
    background-color: ${({ theme }) => theme.color.red950};
    background-image: url('../../images/x-arrows.svg');
    background-size: 180%;
    background-position: center;
    flex-direction: column;
    padding-bottom: ${({ theme }) => theme.sizes.size80}px;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(${({ vh }) => vh} * 100);
    transition: all 400ms ease-in-out;
    transform: translateX(${({ open }) => (open ? '0%' : '100%')});
  `}
`

export const HeaderButton = styled(ButtonOutline)`
  flex-shrink: 0;

  ${media.greaterThan('md')`
    margin-left: ${({ theme }) => theme.sizes.size32}px;
  `}

  ${media.lessThan('md')`
    width: 100%;
    border-radius: 0;
    position: fixed;
    bottom: 0;
    font-size: ${({ theme }) => rem(theme.sizes.size20)};
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.gray950};
    padding: ${({ theme }) => theme.sizes.size40}px ${({ theme }) =>
    theme.sizes.size32}px;
  `}
`

export const HeaderMenuList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const HeaderMenuLink = styled(Link)`
  cursor: pointer;
  font-family: var(--nudistaweb);
  font-size: ${({ theme }) => rem(theme.sizes.size16)};
  font-weight: 600;
  color: ${({ theme }) => theme.color.white};

  &.active,
  &:hover {
    color: ${({ theme }) => theme.color.red950} !important;
  }

  ${media.lessThan('md')`
    color: ${({ theme }) => theme.color.white} !important;
    font-size: ${({ theme }) => rem(theme.sizes.size24)};

    &.active,
    &:hover {
      color: ${({ theme }) => theme.color.white} !important;
    }

    &:hover {
      color: ${({ theme }) => theme.color.white} !important;
    }
  `}
`

export const HeaderMenuItem = styled.li`
  margin-left: ${({ theme }) => theme.sizes.size16}px;
  margin-right: ${({ theme }) => theme.sizes.size16}px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${media.lessThan('md')`
    width: calc(100% - 64px);
    margin-left: ${({ theme }) => theme.sizes.size24}px !important;
    margin-right: ${({ theme }) => theme.sizes.size24}px !important;
    margin-bottom: 0 !important;
    padding: ${({ theme }) => `${theme.sizes.size12}px 0`};

    &:last-child {
      padding-bottom: 0;
    }
  `}

  ${media.lessThan('sm')`
    margin-left: 0;
    margin-right: 0;

    &:not(:last-of-type) {
      margin-bottom: ${({ theme }) => theme.sizes.size12}px;
    }
  `}
`

export const HeaderNavbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: ${({ theme }) => theme.sizes.size80}px;
  visibility: visible;
  transition: all 100ms ease;

  &.menu-closed {
    &.sticky-enabled {
      top: 0;
      background-color: ${({ theme }) => theme.color.white};
      box-shadow: 0 3px 9px ${({ theme }) => rgba(theme.color.gray700, 0.15)};

      ${HeaderBrand} {
        h1 {
          width: 120px;
          height: 26px;
        }
      }
    }
  }

  ${media.greaterThan('md')`
    top: ${({ theme }) => theme.sizes.size32}px;

    &.sticky-enabled {
      ${HeaderMenuLink} {
        color: ${({ theme }) => theme.color.gray950};
      }
    }

    &.sticky-disabled {
      ${HeaderMenuLink} {
        color: ${({ theme }) => theme.color.white};
      }
    }
  `}
`
