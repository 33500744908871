export default {
  colors: {
    closeIcon: '#495160',
    content: '#fff',
    border: 'transparent',
    overlay: 'rgba(0, 0, 0, 0.75)'
  },
  sizes: {
    small: 320,
    medium: 800,
    large: 1100
  }
}
