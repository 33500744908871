export default {
  size0: 0,
  size2: 2,
  size4: 4,
  size8: 8,
  size10: 10,
  size12: 12,
  size14: 14,
  size16: 16,
  size20: 20,
  size24: 24,
  size28: 28,
  size32: 32,
  size40: 40,
  size48: 48,
  size56: 56,
  size64: 64,
  size72: 72,
  size80: 80,
  size88: 88,
  size96: 96,
  size100: 100,
  size110: 110,
  size120: 120,
  size140: 140,
  size192: 192,
  size200: 200,
  size240: 240,
  size320: 320,
  size400: 400,
  size640: 640,
  size960: 960
}
