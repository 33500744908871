import React from 'react'
import ScrollLock from 'react-scrolllock'
import { ToastContainer } from 'react-toastify'

import ThemeProvider from '@superlogica/themeprovider'
import PropTypes from 'prop-types'

import { GlobalStyles, theme } from '../../style'
import Footer from '../Footer'
import Header from '../Header'
import * as S from './styled'

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastContainer />
      <Header />
      <ScrollLock isActive={false}>
        <S.LayoutWrapper>{children}</S.LayoutWrapper>
      </ScrollLock>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
