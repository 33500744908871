export default {
  fontFamily: 'nudista-web',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: 'normal',
  fontWeight: '600',
  textAlign: 'center',
  size: {
    small: {
      padding: '8px 16px',
      fontSize: '14px',
      lineHeight: '14px',
      shadow: '0 8px 8px -8px',
      shadowH: '0 10px 10px -8px'
    },
    medium: {
      padding: '12px 16px',
      fontSize: '16px',
      lineHeight: '16px',
      shadow: '0 8px 8px -8px',
      shadowH: '0 10px 10px -8px'
    },
    large: {
      padding: '16px 24px',
      fontSize: '20px',
      lineHeight: '20px',
      shadow: '0 8px 8px -8px',
      shadowH: '0 10px 10px -8px'
    },
    xlarge: {
      padding: '20px 24px',
      fontSize: '24px',
      lineHeight: '24px',
      shadow: '0 16px 16px -16px',
      shadowH: '0 20px 20px -16px'
    }
  }
}
