export default [
  {
    label: 'Política de privacidade',
    url:
      'https://transparencia.superlogica.com/privacidade/politica-de-privacidade-e-protecao-de-dados/',
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    label: 'Termos de serviço',
    url: 'https://transparencia.superlogica.com/termos/termos-de-servico/',
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    label: 'Código de conduta',
    url: 'https://transparencia.superlogica.com/compliance/codigo-de-conduta/',
    target: '_blank',
    rel: 'noopener noreferrer'
  }
]
