import { rem } from 'polished'
import styled from 'styled-components'

import SuperlogicaIcon from '../../../static/images/superlogica-icon.svg'
import media from '../../helpers/media'

export const FooterWrapper = styled.footer`
  width: 100%;
  overflow: hidden;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FooterSupport = styled.div`
  background-color: ${({ theme }) => theme.color.red950};
  padding: ${({ theme }) => theme.sizes.size32}px 0;

  ${media.lessThan('md')`
    padding: ${({ theme }) => theme.sizes.size48}px 0;

    ${FooterContent} {
      flex-direction: column;
      justify-content: center;
    }
  `}
`

export const FooterCompliance = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.sizes.size32}px 0;

  ${FooterContent} {
    ${media.lessThan('lg')`
      flex-direction: column-reverse;
    `}
  }
`

export const FooterSuperlogica = styled.div`
  width: ${({ theme }) => theme.sizes.size48}px;
  height: ${({ theme }) => theme.sizes.size48}px;
  flex-shrink: 0;

  ${media.lessThan('md')`
    width: ${({ theme }) => theme.sizes.size64}px;
    height: ${({ theme }) => theme.sizes.size64}px;
    margin-bottom: ${({ theme }) => theme.sizes.size32}px;
  `}
`

export const FooterLogo = styled(SuperlogicaIcon)`
  width: 100%;
  height: 100%;
`

export const FooterTitle = styled.h3`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => rem(theme.sizes.size20)};
  flex-shrink: 0;
  font-weight: 600;

  span {
    font-family: var(--nudistaweb);
    font-size: ${({ theme }) => rem(theme.sizes.size16)};
    display: block;
    font-weight: normal;
  }

  ${media.lessThan('md')`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.sizes.size16}px;
  `}
`

export const FooterSupportLink = styled.a`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => rem(theme.sizes.size20)};
  flex-shrink: 0;
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.color.white};
    opacity: 0.75;
  }

  ${media.lessThan('md')`
    margin-bottom: ${({ theme }) => theme.sizes.size8}px;
  `}
`

export const FooterSocial = styled.nav`
  margin-left: ${({ theme }) => theme.sizes.size32}px;

  ${media.lessThan('md')`
    width: ${({ theme }) => theme.sizes.size192}px;
    margin-top: ${({ theme }) => theme.sizes.size16}px;
    margin-left: 0;
  `}
`

export const FooterCopyright = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${({ theme }) => theme.sizes.size20}px;

  span {
    display: block;
  }

  ${media.lessThan('lg')`
    width: 100%;
    margin-right: 0;
    margin-top: ${({ theme }) => theme.sizes.size32}px;
    padding-top: ${({ theme }) => theme.sizes.size20}px;
    border-top: 1px solid ${({ theme }) => theme.color.gray100};
  `}
`

export const FooterText = styled.p`
  font-weight: 400;
  font-family: var(--nudistaweb);
  font-size: ${({ theme }) => rem(theme.sizes.size14)};

  ${media.greaterThan('md')`
    span {
      display: block;
    }
  `}

  ${media.lessThan('md')`
    font-size: ${({ theme }) => rem(theme.sizes.size12)};
  `}
`

export const FooterMenu = styled.nav`
  ${media.lessThan('lg')`
    width: 100%;
  `}
`

export const FooterMenuList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const FooterMenuItem = styled.li`
  margin-left: ${({ theme }) => theme.sizes.size10}px;
  margin-right: ${({ theme }) => theme.sizes.size10}px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${media.lessThan('sm')`
    margin-left: 0;
    margin-right: 0;

    &:not(:last-of-type) {
      margin-bottom: ${({ theme }) => theme.sizes.size12}px;
    }
  `}
`

export const FooterMenuLink = styled.a`
  cursor: pointer;
  font-family: var(--nudistaweb);
  font-weight: 600;
  font-size: ${({ theme }) => theme.sizes.size14}px;

  &:hover {
    color: ${({ theme }) => theme.color.red950};
  }
`
