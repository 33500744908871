import { Link } from 'gatsby'
import styled from 'styled-components'

import media from '../../helpers/media'

export const BrandWrapper = styled.div`
  flex-shrink: 0;
  transition: all 100ms ease;
  position: relative;
  z-index: 2000;
`

export const BrandLink = styled(Link)`
  display: block;
`

export const BrandLogo = styled.h1`
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../images/brand-light.svg');
  text-indent: -9999px;
  width: 160px;
  height: 35px;
  display: block;

  ${media.lessThan('md')`
    width: 120px;
    height: 26px;
  `}

  ${({ dark, open }) => {
    if (dark && !open) {
      return `
        background-image: url(../../images/brand-dark.svg);
      `
    }

    if (open) {
      return media.lessThan('md')`
        background-image: url(../../images/brand-gray.svg);
      `
    }
  }}
`
