import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const Brand = ({ className, dark, open }) => (
  <S.BrandWrapper className={className}>
    <S.BrandLink to="/" title="Superlógica Xperience">
      <S.BrandLogo dark={dark} open={open}>
        Superlógica Xperience
      </S.BrandLogo>
    </S.BrandLink>
  </S.BrandWrapper>
)

Brand.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  open: PropTypes.bool
}

export default Brand
